<template>
  <!-- 悬浮的 提示 -->
  <bottom-tips :detail="kanbanDetail"></bottom-tips>
  <log-tips :statistics="statistics" @toDetail="toDetail"></log-tips>
  <div :style="{overflowX:reservationBoardList.length>8?'auto':'hidden'}">
    <div ref="sraffNameRef" class="teacher-title">
      <div class="title-wrap">
        <template :key="item.empId" v-for="item in reservationBoardList">
          <div class="date-item" @click="handleTeacherClick(item)">
            {{ item.empName }}<span>({{ item.customerCount||0 }})</span>
          </div>
        </template>
      </div>
    </div>

    <div ref="bottomsraffNameRef" class="bottom-date-bar">
      <div ref="titleWrap2" class="title-wrap">
        <template :key="item" v-for="item in staffList">
          <div class="date-item">
            <div class="content-item"></div>
          </div>
        </template>
      </div>
    </div>
    <div class="physiotherapy-list" :style="{width:reservationBoardList.length>8?((reservationBoardList&&reservationBoardList.length+1)*140)+400+'px':''}">
      <div class="time">
        <div ref="timeScaleRef" class="time-scale">
          <template v-if="ruleForm.timeDiff == 10">
            <template :key="item" v-for="item in reserveTimeList">
              <div>
                {{ item.time }}
              </div>
            </template>
          </template>

          <template v-if="ruleForm.timeDiff == 30">

            <template :key="item" v-for="item in reserveTimeList">
              <div v-if="item.thirty"> <!---->
                {{ item.time }}
              </div>
            </template>
          </template>

          <template v-if="ruleForm.timeDiff == 60">

            <template :key="item" v-for="item in reserveTimeList">
              <div v-if="item.sixty"> <!---->
                {{ item.time }}
              </div>
            </template>
          </template>
        </div>
        <div ref="mainContentRef" v-loading.fullscreen.lock="loading" element-loading-text="数据加载中，请稍等……" class="line-list" :class="'list-'+ruleForm.timeDiff" v-if="reserveTimeList.length && reservationBoardList.length" @mouseleave="handleReserveLeave2">
          <template :key="indey" v-for="(t, indey) in reserveTimeList">
            <div class="line-box" :style="{height: getH() + 'px'}" :class="{ 'last': indey === reserveTimeList.length - 1 }">
              <!--v-if="checkIsShowLine(indey)"-->
              <template :key="index" v-for="(item, index) in reservationBoardList">
                <!-- 已超时 -->
                <template v-if="!(getLineItem(indey, index)?.isDisplay)">
                  <div class="line-item cursor" @mouseenter.stop="handleReserveLeave1()">
                    <div v-if="getLineItem(indey, index)?.vacation||getLineItem(indey, index)?.appStatus == 4" class="reserve-item restTime" :style="{
                        height: getH() + 'px',
                        top: indey * getH() /  + 'px',
                        left: index * 140 + 'px',
                      }">
                      <!-- <span>休息</span> -->
                    </div>
                    <div v-if="getLineItem(indey, index)?.isOccupancy" class="reserve-item occupyTime" :style="{
                      height: getH()+ 'px',
                      top: indey * getH() + 'px',
                      left: index * 140 + 'px',
                    }">
                      已占用
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="line-item cursor" :style="{height: getH() + 'px'}" :class="{ paibanActive: getLineItem(indey, index)?.appStatus == 0}" @mouseleave.stop="handleReserveLeave" @mouseenter.stop="handleReserveClick(indey, index)">
                    <!-- {{  getLineItem(indey, index)?.status}} -->
                    <!--休息/空闲-->
                    <div v-if="getLineItem(indey, index)?.vacation||getLineItem(indey, index)?.appStatus == 4" class="reserve-item restTime" :style="{
                      height: getH() + 'px',
                      top: indey * getH() + 'px',
                      left: index * 140 + 'px',
                    }"></div>
                    <!-- <div v-if="getLineItem(indey, index)?.status == 3" class="reserve-item occupyTime" :style="{
                      height: '72px',
                      top: indey * 72 + 'px',
                      left: index * 140 + 'px',
                    }" @click="">
                      已占用
                    </div> -->

                    <!-- 有排班 && 空闲 -->
                    <div class="dialog" v-if="getLineItem(indey, index)?.appStatus == 0 && currentTimeIndex == indey &&
                      currentStaffIndex == index && isShowDialog&& !getLineItem(indey, index)?.vacation ">
                      <div v-has="'board:add'" @click.stop="handleAddReserveClick(indey, index)">
                        新建预约
                      </div>
                      <div @click.stop="handleAddTimeOccupyClick(indey, index)">
                        新建占用
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </template>

          <!--订单信息 1待到店2已到店3已开单4已完成5已取消6已退款-->
          <template v-for="(odr, idx) in orderArr" :key="idx">
            <div v-if="odr.content.isOccupancy" class="order-item" :class="{ 'occupy': odr.content.isOccupancy }" @click="openOrderPopup(odr.content)" :style="{
                height: odr.location.h * getH() + 'px',
                top: odr.location.y *  getH() + 'px',
                left: odr.location.x * 140 + 'px',
              }">
              <div class="order-details">
                已占用
              </div>
              <p style="font-size: 12px;margin-top: 10px;">{{occupyTypeformat(odr.content.cause)}}</p>
            </div>
            <div v-else class="order-item" :class="['status-' + odr.content.appStatus,]" @click="handleReserveDetailClick(odr.content,idx)" :style="{
                height: odr.location.h * getH() + 'px',
                top: odr.location.y * getH() + 'px',
                left: odr.location.x * 140 + 'px',
              }" @mouseenter.stop="odr.content.appStatus==1||odr.content.appStatus==2||odr.content.appStatus==4?mouseEnter(idx,odr.content):''" @mouseleave.stop="mouseOut($event,idx)">
              <div class="order-details">
                <p class="tips" :style="odr.content.isTargetCustomer?'color:red;':''">
                  {{ odr.content.userName }}
                  <span class="ispoint" v-if="odr.content.isNewCustomer">新客</span>
                  <span class="ispoint" v-if="odr.content.isPoint">回客</span>
                </p>

                <p>{{ odr.content.phone }}<span v-if="odr.content.room">({{ odr.content.room }})</span></p>
                <p v-for="(items,index) in odr.content.serveName" :key="index">
                  <span>{{ items }}</span>
                </p>
                <p v-if="odr.content.remark">
                  <span>预约备注：{{ odr.content.remark||'无备注' }}</span>
                </p>
                <p v-if="odr.content.orderRemark">
                  <span>订单备注：{{ odr.content.orderRemark||'无备注' }}</span>
                </p>
              </div>
            </div>
            <div v-if="odr.checked" class="detail_pop"  :style="{
                top: odr.location.y * getH() + 'px',
                left: (odr.location.x * 140)+100 + 'px',
              }"  @mouseleave.stop="mouseOut($event,idx)">
              <order-card :currentDialog="currentDialog" :roomOptions="roomOptions" @confirmAppAction="confirmAppAction" 
              @cancelAppAction="cancelAppAction"
              @handleReserveDetailClick="handleReserveDetailClick" @makeABillAction="makeABillAction" ></order-card>
            </div>
          </template>
        </div>

      </div>
    </div>
  </div>
  <page-dialog width="650px" :dialogVisible="dialogVisible" title="占用详情" @changeDialogVisible="handleCloseDialog">
    <occupy-card :currentDialog="currentDialog" @cancelOccupancy="cancelOccupancy"></occupy-card>
  </page-dialog>

  <add-reserve-dialog ref="addReserveForm" :currentStaffIndex="currentStaffIndex" :addInfo="addInfo" :dialogVisible="addReserveDialogVisible" @changeDialogVisible="changeAddReserveDialog">
  </add-reserve-dialog>
  <add-time-occupy-dialog v-if="addTimeDialogVisible" @refreshOccupyTime="refreshPage" :addInfo="addInfo" :dialogVisible="addTimeDialogVisible" @changeDialogVisible="changeAddTimeDialog" :teacherTimeList="teacherTimeList" @changeDate="changeDate"></add-time-occupy-dialog>
  <reserve-edit-draw v-if="pageEditReserveDialogVisible" :shopTeacherListData="shopTeacherListData" @refreshPage="updatedraw" :isCheck="true" :reserve="currentReserve" @changedDrawer="handleReserveEditdDrawer" :dialogVisible="pageEditReserveDialogVisible">
  </reserve-edit-draw>
  <template v-if="paydialogVisible">
    <payDialog
      :paydialogVisible="paydialogVisible"
      :isKaika="true"
      :curentOrderInfo="curentOrderInfo"
      @changeDialogVisible="payChangeDialogVisible"
      :dialogVisible="paydialogVisible"
      @paySuccess="paySuccess"
    >
    </payDialog>
  </template>
</template>

<script>
import { defineComponent, reactive, ref, computed, watch, onMounted, onUnmounted, defineExpose, nextTick } from 'vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import addReserveDialog from '../base-ui/add-reserve-dialog/add-reserve-dialog'
import cache from '@/utils/cache'
import emitter from '@/utils/eventbus'
import addTimeOccupyDialog from '../base-ui/add-time-occupy-dialog/add-time-occupy-dialog.vue'
import dayjs from 'dayjs'
import reserveEditDraw from '../../../list/cpns/reserve-list/cpns/base-ui/reserve-edit-draw/reserve-edit-draw.vue'
import bottomTips from '../base-ui/bottom-tips/bottom-tips.vue'
import logTips from '../base-ui/log-tips/log-tips.vue'
import { useRouter } from 'vue-router'
import { deepClone } from '@/utils/clone'
import { popup } from '@/utils/pop-up'
import { ElMessage } from 'element-plus'
import { reservationBoard, getReserveShopList, getShopTime, confirmApp, makeABill, getNewRooms, getBedListByApp,getAppointment,newCancelorder, pullCashier,getBulletinBoardStatusCount ,getUserTimeList,getOccupation,getTargetCustomersStatistics} from '@/service/main/reserve'
import payDialog from '../../../../order/base-ui/pay-dialog/pay-dialog.vue'
import {
  debounce
} from 'lodash'
import OrderCard from "../../components/card.vue"
import OccupyCard from "../../components/occupy.vue"
import {OccupyType} from '../../config/occupyType.js'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
// import { reserveTimeList_30, reserveTimeList_60 } from '../config'
// import { messageComment } from '@/service/main/base'
// import { useShopTime } from '../config'

export default defineComponent({
  props: {
    contentWidth: {
      type: Number,
      default: 1200
    }
  },
  components: {
    addReserveDialog,
    addTimeOccupyDialog,
    reserveEditDraw,
    bottomTips,
    OrderCard,
    payDialog,
    PageDialog,
    OccupyCard,
    logTips
  },
  emits: ['scrollFixed', 'scrollRemoveFixed', 'changeAddReserve', "reservationBoardList"],
  setup(props, { emit }) {

    // 是否是之前的日期 新增预约时 不能新增昨天的
    const isOldDate = ref(true)
    const addReserveForm = ref(null)
    const store = useStore()
    const router = useRouter()
    const kanbanDetail = ref(null)
    const staffList = ref([])
    const reservationBoardList = ref([])
    const reserveTimeList = ref([])
    const orderArr = ref([])
    const dialogVisible = ref(false)
    const currentDialog = ref({})
    const paydialogVisible = ref(false)
    const loading = ref(false)

    const roomOptions = ref([])
    // const reserveTimeList = reactive({
    //   ten:[],
    //   thirty:[],
    //   sixty:[]
    // })
    const toDetail = () =>{
      // console.log("111");
      router.push({
        path: '/main/reserve/saleLog',
        query: {
          shopId: ruleForm.shopId,
          date: ruleForm.time
        }
      })
    }
    const occupyTypeformat =(name)=>{
          let obj = []
          obj = new OccupyType().getByValue(name, 'id')
          return obj ? obj.name : ''
      }

    const openOrderPopup = async(item) => {
      if (item.isOccupancy) {
        orderArr.value.map(item=>{
          item.checked=false
        })
        let data= await getOccupation(item.occupancyNo)
        let flag = judgeTime(data.data.date+' '+data.data.endTime)
        currentDialog.value = {isOccupancy:item.isOccupancy,occupancyNo:item.occupancyNo,...data.data,flag}
        dialogVisible.value = true
      } 
    }
    const judgeTime = (time) => {
      var strtime = time.replace("/-/g", "/");//时间转换
      //时间
      var date1 = new Date(strtime);
      //现在时间
      var date2 = new Date();
      console.log(date1,date2);
      //判断时间是否过期
      return date1 < date2 ? true : false;
    }

    const handleCloseDialog = (flag) => {
      dialogVisible.value = flag
    }
    const getLineItem = (indey, index) => {
      return reservationBoardList.value[index].timeList[indey]
    }
    const ruleForm = reactive({
      shopId: '',
      time: dayjs(new Date()).format('YYYY-MM-DD'),
      timeDiff: '10'
    })

    const refreshShoplist = async (shopId) => {
      if (shopId == 0) return;
      const res = await getReserveShopList(shopId)
      if (res.data && res.data.list && res.data.list.length) {
        let data = res.data.list.filter(ele => ele.appStatus == 1)
        staffList.value = data
      }
    }

    const refreshShopTime = async (shopId) => {
      const times = await getShopTime(shopId)
      if (times.data && times.data.length) {
        reserveTimeList.value = times.data.map((i, idx) => {
          let arr = i.time.split(':');
          return {
            ...i,
            thirty: arr[1] == '30' || arr[1] == '00',
            sixty: arr[1] == '00'
          }
        })
      }

    }
    const statistics = reactive(
      {
        data:{
          targetCustomerCount:0,
          waitingForReview:0,
          waitingPlanCount:0
        }
      }
    )
    const refreshReservationBoard = async (data) => {
      loading.value = true
      let list = await reservationBoard(data)
      let countList = await getBulletinBoardStatusCount(data)
      const resp = await getTargetCustomersStatistics(data)
      statistics.data = resp.data?resp.data:{
        targetCustomerCount:0,
        waitingForReview:0,
        waitingPlanCount:0
      }
      kanbanDetail.value = countList.data.itemList
      reservationBoardList.value = list.data || []
      let obj = {}
      reservationBoardList.value.length && reservationBoardList.value.forEach((item, index) => {
        item.timeList.forEach((t, i) => {
          let { appNo } = t
          if (appNo) {
            if (obj[appNo]) {
              obj[appNo].timeChild.push({
                pIndex: index,
                sIndex: i
              })
            } else {
              let o = {
                content: { ...t, isOccupancy: false },
                timeChild: [{
                  pIndex: index,
                  sIndex: i
                }]
              }
              obj[appNo] = o
            }
          } else {
            let { occupancyNo } = t
            if (t.isOccupancy) {
              if (obj[occupancyNo]) {
                obj[occupancyNo].content.timeStr += `,${t.time}`
                obj[occupancyNo].timeChild.push({
                  pIndex: index,
                  sIndex: i,
                })
              } else {
                const { date, empId, } = item
                let o = {
                  content: { ...t, isOccupancy: true, date, empId, timeStr: t.time },
                  timeChild: [{
                    pIndex: index,
                    sIndex: i
                  }]
                }
                obj[occupancyNo] = o
              }
            }
          }
        })
      })
      console.log('处理之后的预约信息：', obj)
      for (let key in obj) {
        let arr = obj[key].timeChild
        obj[key].checked=false
        obj[key].location = {
          x: arr[0].pIndex,
          y: arr[0].sIndex,
          h: arr.length
        }
      }
      orderArr.value = Object.values(obj)
      emit('reservationBoardList', reservationBoardList.value);
      setTimeout(()=>{
        loading.value = false
      },1000)
    }
    const initPage = async () => {
      let adminInfo = cache.getCache('adminInfo')
      if (ruleForm.shopId == '') {
        ruleForm.shopId = adminInfo.shopId
      }

      let reserveTimeDiff = cache.getCache('reserveTimeDiff')
      if (reserveTimeDiff != undefined) {
        ruleForm.timeDiff = reserveTimeDiff
      }
      refreshPage()
    }

    const isShowDialog = ref(false)
    const currentTimeIndex = ref(-1)
    const currentStaffIndex = ref(-1)
    const handleReserveClick = (indey, index) => {
      // console.log('点击看板')
      currentTimeIndex.value = indey
      currentStaffIndex.value = index
      isShowDialog.value = true
    }
    const addInfo = reactive({
      teacherId: '',
      startTime: '',
      teacherName: '',
      lastTime: "24:00",
      lvid: ""
    })
    const handleAddReserveClick = async(indey, index) => {
      addInfo.lastTime = reserveTimeList.value[reserveTimeList.value.length - 1].time
      addInfo.teacherName = reservationBoardList.value[index].empName
      addInfo.teacherId = reservationBoardList.value[index].empId
      addInfo.startTime = reserveTimeList.value[indey].time
      addInfo.lvid = reservationBoardList.value[index].lvid
      isShowDialog.value = false
      currentStaffIndex.value = index
      addReserveDialogVisible.value = true
      await store.dispatch('reserve/getShopTeacherListAction', ruleForm.shopId)
      nextTick(() => {
        addReserveForm.value.initPage(ruleForm.shopId,addInfo.teacherId)
      })
    }

    const addReserveDialogVisible = ref(false)
    const changeAddReserveDialog = (flag) => {
      addReserveDialogVisible.value = flag
      if (!flag) {
        refreshPage()
      }
    }

    const handleReserveLeave = debounce(() => {
      isShowDialog.value = false
    }, 100)

    const addTimeDialogVisible = ref(false)
    const handleAddTimeOccupyClick = (indey, index) => {
      const item = reservationBoardList.value[index]
      const ele = reserveTimeList.value[indey]
      addInfo.teacherName = item.empName
      addInfo.teacherId = item.empId
      addInfo.startTime = ele.time
      addInfo.lvid = item.lvid
      addInfo.shopId = ruleForm.shopId
      getTeacherTime(item.empId)
    }
    const teacherTimeList = ref([])
    const getTeacherTime = async (empId) => {
      teacherTimeList.value = []
      let arriveDate=cache.getCache('optReserveDate')
      if (empId == '') return
      const res = await getUserTimeList({empId, date:arriveDate,shopId:ruleForm.shopId})
      if(res.data&&res.data.length){
          res.data.map(item=>{
            item.time=item.time.split(':')[0]+':'+item.time.split(':')[1]
          })
          teacherTimeList.value = res.data
          addTimeDialogVisible.value = true
      }
    }
    const changeAddTimeDialog = (flag) => {
      addTimeDialogVisible.value = flag
    }
    
    const changeDate = (arr) => {
      teacherTimeList.value = arr
    }
    const refreshPage = () => {
      refreshShopTime(ruleForm.shopId)
      refreshShoplist(ruleForm.shopId)
      refreshReservationBoard({
        date: ruleForm.time,
        shopId: ruleForm.shopId
      })
    }
    // created
    initPage()


    const checkIsShowLine = (indey) => {
      let res = false
      if (ruleForm.timeDiff == 10) {
        res = true
      }
      if (ruleForm.timeDiff == 30) {
        res = reserveTimeList.value[indey].thirty
      }
      if (ruleForm.timeDiff == 60) {
        res = reserveTimeList.value[indey].sixty
      }
      return res
    }


    emitter.on('changeAddReserveShopId', async shopId => {
      // debugger
      ruleForm.shopId = shopId
      refreshShopTime(shopId)
      refreshShoplist(shopId)
      refreshReservationBoard({
        date: ruleForm.time,
        shopId
      })
    })
    emitter.on('changeAddReserveTime', date => {
      // console.log('改变日期', date)
      ruleForm.time = date
      refreshReservationBoard({
        date,
        shopId: ruleForm.shopId
      })
    })
    emitter.on('refreshPhysiotherapylistTimediff', diff => {
      ruleForm.timeDiff = diff
      // console.log('33333333!!!!!', ruleForm)
    })

    emitter.on('changeAddReservePageStaffReserveList', () => {
      // console.log('changeAddReservePageStaffReserveList!!!!!')
      // debugger
      refreshPage()
    })
    onUnmounted(() => {
      emitter.off('changeAddReserveTime')
      emitter.off('refreshPhysiotherapylistTimediff')
      emitter.off('changeAddReserveShopId')
      emitter.off('changeAddReservePageStaffReserveList')
    })

    const currentReserve = ref('')
    const pageEditReserveDialogVisible = ref(false)
    const handleReserveEditdDrawer = (flag) => {
      pageEditReserveDialogVisible.value = flag
      emit('changeAddReserve', flag)
      if (!flag) {
        currentReserve.value=''
      }
    }

    const shopTeacherListData = ref([])
    const handleReserveDetailClick = async(item,index) => {
      const data = await getAppointment(item.id)
      const teaRes = await getReserveShopList(data.data.shopId)
      let teacherList = teaRes.data.list
      teacherList = teacherList.filter(ele => ele.status == 1)
      shopTeacherListData.value = teacherList
      orderArr.value[index].checked = false
      currentReserve.value = data.data
      pageEditReserveDialogVisible.value = true
      // reservedForm.show = false
    }

    const updatedraw = () => {
      dialogVisible.value = false
      // debugger
      refreshPage()
    }

    const confirmAppAction = (e) => {
      confirmApp(e).then(res => {
        if (res.code == 0) {
          ElMessage({
            message: '操作成功!',
            type: 'success',
          })
          // debugger
          refreshPage()
          // dialogVisible.value = false
        } else {
          ElMessage.error(res.msg)
          // dialogVisible.value = false
        }
      })
    }
    const cancelAppAction = (e) => {
      popup('确定取消预约?', () => {
        newCancelorder(e).then(res => {
          if (res.code == 0) {
          ElMessage({
            message: '操作成功!',
            type: 'success',
          })
          refreshPage()
        } else {
          ElMessage.error(res.msg)
        }
        })
      })
    }
    const cancelOccupancy = () => {
      dialogVisible.value = false
      // debugger
      refreshPage()
    }
    const curentOrderInfo = ref({})
    const makeABillAction =async() => {
      if (currentDialog.value.isBill) {
        let payData = await pullCashier(currentDialog.value.orderId)
        curentOrderInfo.value = payData.data
        paydialogVisible.value=true
        setTimeout(() => {
          emitter.emit('changeOrderPayDialogSearchPayState')
        }, 2000);
        return
      }
      const { id } = currentDialog.value
      router.push({
        path: '/main/order/billing',
        query: {
          userId: currentDialog.value.empId,
          id,
          phone: currentDialog.value.phone,
          shopId:ruleForm.shopId
        }
      })
    }

    const handleTeacherClick = (item) => {
      // cache.setCache('optReserveDate', ruleForm.time)
      // router.push({
      //   path: '/main/reserve/teacherDetailCheck',
      //   query: {
      //     userId: item.empId,
      //     userRealname: item.empName,
      //     shopName: item.shopName,
      //     shopId: ruleForm.shopId,
      //     timeDiff: ruleForm.timeDiff
      //   }
      // })
    }
    const getH = () => {
      const diff = +ruleForm.timeDiff / 10
      return 72 / diff
    }

    const mouseEnter = debounce(async(index,item) =>{
      const data = await getAppointment(item.id)
      currentDialog.value = {...data.data,matchingScheme:item.matchingScheme}
      if (!item.isOccupancy) {
        const roomList=await getBedListByApp(item.id)
        roomOptions.value = roomList.data.map(t => ({
          ...t,
          enabled: t.bedList.some(b => b.enabled)
        }))
        if (![1, 2, 4].includes(item.appStatus)) return
      }
      orderArr.value.map(item=>{
        item.checked=false
      })
      orderArr.value[index].checked = true
    },500)
    const mouseOut = (e,index) =>{
      var currTargetEl = e.relatedTarget || e.toElement
      if (currTargetEl) {
          const elTagName = currTargetEl.tagName
          const targetClassName = currTargetEl.className
          if (elTagName === 'DIV' && (targetClassName.indexOf('el-select__popper el-popper is-light is-pure') !== -1||targetClassName.indexOf('el-select-dropdown__wrap el-scrollbar__wrap el-scrollbar__wrap--hidden-default') !== -1||targetClassName.indexOf('detail_pop') !== -1||targetClassName.indexOf('flex_box') !== -1||targetClassName.indexOf('el-popover--plain el-popover el-popper is-light') !== -1||targetClassName.indexOf('el-select__popper eloption el-popper is-light is-pure') !== -1)) {
            return false
          } else if (elTagName === 'LI' && targetClassName.indexOf('el-select-dropdown__item') !== -1) {
            return false
          } else if (elTagName === 'UL' && targetClassName.indexOf('el-select-dropdown__list') !== -1) {
            return false
          } else if (!e.target) {
            return false
          } else {
            orderArr.value[index].checked = false
          }
      }
    }
    const handleReserveLeave2= (e) =>{
      var currTargetEl = e.relatedTarget || e.toElement
      if (currTargetEl) {
          const elTagName = currTargetEl.tagName
          const targetClassName = currTargetEl.className
          if (elTagName === 'DIV' && (targetClassName.indexOf('el-select__popper el-popper is-light is-pure') !== -1||targetClassName.indexOf('el-select-dropdown__wrap el-scrollbar__wrap el-scrollbar__wrap--hidden-default') !== -1||targetClassName.indexOf('el-popover--plain el-popover el-popper is-light') !== -1||targetClassName.indexOf('el-select__popper eloption el-popper is-light is-pure') !== -1)) {
            return false
          } else if (elTagName === 'LI' && targetClassName.indexOf('el-select-dropdown__item') !== -1) {
            return false
          } else if (elTagName === 'UL' && targetClassName.indexOf('el-select-dropdown__list') !== -1) {
            return false
          } else if (!e.target) {
            return false
          } else {
            setTimeout(()=>{
              orderArr.value.map(item=>{
                item.checked=false
              })
            },500)
          }
      }
    }
    const handleReserveLeave1= () =>{
      orderArr.value.map(item=>{
        item.checked=false
      })
    }
    const payChangeDialogVisible = (flag) => {
      paydialogVisible.value = flag
    }
    const paySuccess = () => {
      paydialogVisible.value = false
      refreshPage()
    }

    watch(paydialogVisible, (n) => {
      if (n == false) {
        setTimeout(() => {
          clearTimeout(window.timer)
        }, 5000);
      }
      // console.log(n);

    }, {
      deep: true
    })
    return {
      getH,
      isOldDate,
      ruleForm,
      reserveTimeList,
      staffList,
      kanbanDetail,
      reservationBoardList,
      handleReserveClick,
      isShowDialog,
      currentTimeIndex,
      currentStaffIndex,
      handleAddReserveClick,
      handleAddTimeOccupyClick,
      addTimeDialogVisible,
      handleReserveLeave,
      handleReserveLeave1,
      handleReserveLeave2,
      getLineItem,
      checkIsShowLine,
      refreshShoplist,
      orderArr,
      dialogVisible,
      currentDialog,
      openOrderPopup,
      handleCloseDialog,
      roomOptions,
      confirmAppAction,
      makeABillAction,
      addInfo,
      addReserveDialogVisible,
      changeAddReserveDialog,
      changeAddTimeDialog,
      handleReserveEditdDrawer,
      pageEditReserveDialogVisible,
      currentReserve,
      handleReserveDetailClick,
      cancelOccupancy,
      updatedraw,
      handleTeacherClick,
      refreshPage,
      addReserveForm,
      mouseEnter,
      mouseOut,
      cancelAppAction,
      paydialogVisible,
      payChangeDialogVisible,
      curentOrderInfo,
      paySuccess,
      getTeacherTime,
      teacherTimeList,
      changeDate,
      occupyTypeformat,
      statistics,
      toDetail,
      shopTeacherListData,
      loading
    }

  }
})

</script>

<style scoped lang="less">
.tips {
  position: relative;
}
.ispoint {
  display: inline-block;
  height: 16px;
  border-radius: 2px;
  border: 1px solid #fd678b;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  background-color: #fff;
  font-weight: 400;
  color: #fd678b;
  line-height: 16px;
  padding: 0 5px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
}

.bottom-date-bar {
  position: fixed;
  z-index: 1000;
  bottom: 10px;
  overflow-x: auto;

  .date-item {
    height: 1px !important;
  }
}

.title {
  overflow: hidden;
}
.title-wrap {
  width: 100%;
  display: flex;
  margin-left: 80px;
  position: relative;
  .date-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    min-width: 140px;
    font-weight: 600;
    font-size: 14px;

    .date-week {
      margin-top: 6px;
      font-size: 13px;
    }

    div {
      width: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(80, 80, 80, 1);
      font-size: 14px;
    }
  }
}

.pagination {
  text-align: right;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.line-box.paibanActive:nth-of-type(1) {
  border-left: 1px solid rgb(220, 220, 220);
}

.paibanActive {
  // height: 72px !important;
  border-right: 1px solid rgba(201, 197, 197, 1);
  background-color: white !important;
}

.shop-room {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &:deep(.el-select) {
    max-width: 146px;
  }
}

.staffNameFiexed {
  position: fixed;
  width: 88.5% !important;
  top: 180px;
  background-color: white;
  z-index: 100;
  border-bottom: 2px solid rgb(241, 243, 244);
}

.title {
  width: 100%;
  display: flex;
  // padding-left: 80px;

  // div {
  //   width: 112px;
  //   min-width: 112px;
  //   height: 55px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   color: rgba(80, 80, 80, 1);
  //   font-size: 14px;
  //   cursor: pointer;
  //   word-spacing: normal;
  //   font-weight: 700;
  // }
}

.physiotherapy-list {
  position: relative;
  padding-bottom: 250px;
  padding-top: 8px;
  height: 70vh;
  overflow-y: scroll;

  .reserve-dialog {
    position: absolute;
    z-index: 99;
    width: 343px;
    color: rgba(80, 80, 80, 1);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    padding: 20px;
    background-color: #fff;
    overflow: hidden;

    .opt-select {
      margin: 20px 0;

      &:deep(.el-select) {
        width: 100%;
      }
    }

    .caozuo-btn {
      display: flex;
      justify-content: space-between;

      &:deep(.button1) {
        flex: 1.5;
      }

      &:deep(.button2) {
        flex: 1;
      }
    }

    .genjin {
      position: relative;
      padding-bottom: 18px;

      &:deep(.el-input__inner) {
        height: 50px;
        line-height: 50px;
      }

      &:deep(.el-input__suffix) {
        top: 6px;
      }

      .genjin-text {
        position: absolute;
        width: 100%;
      }

      .submit {
        position: absolute;
        z-index: 100;
        right: 4px;
      }
    }

    .current-tips {
      color: rgba(80, 80, 80, 1);
      background-color: rgba(239, 239, 239, 1);
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 20px 0;

      div {
        line-height: 30px;
        text-align: center;
      }
    }

    .top {
      display: flex;
      justify-content: space-between;

      .btns {
        display: flex;
        flex-direction: column;

        &:deep(.el-tag) {
          margin-top: 10px;
        }

        .other {
          cursor: pointer;
          font-size: 12px;
          color: rgb(102, 177, 255);
          text-align: center;
        }

        .cancle {
          margin-top: 7px;
          width: 60px;
          height: 25px;
          background-color: rgba(255, 255, 255, 1);
          color: rgb(96, 123, 181);
          border-radius: 3px;
          font-size: 12px;
          text-align: center;
          line-height: 25px;
          cursor: pointer;
          box-sizing: border-box;
          border: 1px solid rgb(220, 223, 230);

          &:hover {
            border: 1px solid rgba(42, 130, 228, 1);
            color: rgba(42, 130, 228, 1);
          }
        }
      }

      .user-info {
        display: flex;

        .uname {
          .user-name {
            cursor: pointer;
            color: rgb(64, 158, 255);
          }

          .name {
            color: rgba(80, 80, 80, 1);
            font-size: 16px;
            display: flex;
          }

          .phone {
            color: rgba(80, 80, 80, 1);
            font-size: 14px;
            margin-top: 4px;
          }
        }

        .avatar {
          margin-right: 10px;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .occupyTime {
    color: #fff;
    background-color: rgb(240, 183, 89);
  }
  .restTime {
    // background: repeating-linear-gradient(50deg, #000, #000 5px, #fff 5px, #fff 12px);

    background: repeating-linear-gradient(
      45deg,
      #d3d3d3,
      #d3d3d3 2px,
      #e5e5e5 0,
      #e5e5e5 16px
    );
    background-repeat: repeat-y;
    // border-right: 1px solid rgb(201, 197, 197) !important;

    span {
      z-index: 999;
    }
  }

  .reserve-item {
    // border: 1px solid white;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 12px;
    padding-top: 20px;
    cursor: pointer;

    & > div:nth-child(2) {
      margin-top: 2px;
    }
  }

  

  .time {
    display: flex;
    width: 100%;
    position: relative;

    // overflow: hidden;
    .time-scale {
      position: absolute;
      z-index: 89;
      width: 80px;
      top: -8px;
      background-color: #fff;

      // display: flex;
      // flex-wrap: wrap;
      div {
        height: 72px;
        color: rgba(80, 80, 80, 1);
        font-size: 14px;
        text-align: center;
      }

      & > div:nth-child(4n + 1) {
        font-weight: 556;
      }
    }

    .line-list {
      position: absolute;
      z-index: 9;
      cursor: pointer;
      // width: calc(100% - 80px);
      left: 80px;
      padding-bottom: 380px;

      .order-item {
        position: absolute;
        // background: red;
        color: #fff;
        width: 140px;
        box-sizing: border-box;
        border: 1px solid #c9c5c5 !important;
        cursor: pointer;

        &.status-1 {
          background-color: rgb(121, 72, 234);
        }

        &.status-2 {
          background-color: rgb(36, 153, 84);
        }

        &.status-3 {
          background-color: rgb(42, 130, 228);
        }

        &.status-4 {
          background-color: rgb(19, 194, 194);
        }

        &.status-5 {
          background-color: rgb(240, 183, 89);
        }
        &.status-6 {
          background-color: rgb(227, 91, 91);
        }

        &.occupy {
          background-color: rgb(240, 183, 89) !important;
        }

        .order-details {
          // padding: 6px;

          p {
            padding: 0 5px;
            word-break: break-all;
            color: #fff;
            font-size: 12px;
            line-height: 20px;
          }
        }
        
      }
      .detail_pop{
          width: 420px;
          min-height: 400px;
          background: #fff;
          border: 1px solid #ccc;
          position: absolute;
          color: #000;
          z-index: 9999;
        }

      &.list-10 {
        .line-box {
          &:first-child {
            .line-item {
              border-top: 1px solid rgb(201, 197, 197);
            }
          }
          .line-item {
            height: 72px;
            border-bottom: 1px solid rgb(201, 197, 197);
          }
        }
      }
      &.list-30 {
        .line-box {
          &:first-child {
            .line-item {
              border-top: 1px solid rgb(201, 197, 197);
            }
          }
          &:nth-child(3n) {
            .line-item {
              border-bottom: 1px solid rgb(201, 197, 197);
            }
          }
          .line-item {
            height: 24px;
          }
        }
      }

      &.list-60 {
        .line-box {
          &:first-child {
            .line-item {
              border-top: 1px solid rgb(201, 197, 197);
            }
          }
          &:nth-child(6n) {
            .line-item {
              border-bottom: 1px solid rgb(201, 197, 197);
            }
          }
          .line-item {
            height: 12px;
          }
        }
      }

      .line-box {
        display: flex;

        &.last div {
          border-bottom: 1px solid rgb(201, 197, 197);
        }

        & > div:nth-child(1) {
          border-left: 1px solid rgb(201, 197, 197);
        }

        .cursor {
          cursor: pointer;
        }

        .line-item {
          position: relative;
          width: 140px;
          min-width: 140px;
          height: 72px;
          overflow: hidden;
          // border-top: 1px solid rgb(201, 197, 197);
          background-color: #e5e5e5;
          border-right: 1px solid rgb(201, 197, 197);
          // padding-top: 1px;

          .dialog {
            display: flex;
            flex-direction: column;
            background-color: rgb(77, 77, 77);
            color: white;
            font-size: 13px;
            text-align: center;

            .empty-class {
              background-color: rgb(211, 211, 211);
              height: 72px;
              color: rgb(63, 60, 60);
              line-height: 72px;
            }

            div {
              line-height: 36px;
              cursor: pointer;
            }
          }
        }
      }

      & > div {
        height: 72px;
        z-index: 9;
        // border-top: 1px solid rgba(220, 220, 220, 1);
      }

      // &>div:last-child .line-item {
      //   border-bottom: 1px solid rgb(201, 197, 197);
      // }
    }
  }
}
</style>
